<template>
    <div class="has-img f-w">
        <div class="f-w mr-20" style="position: relative;" v-for="(item, index) of imageUrlList" :key="index">
            <el-image class="mb-30 borderRa-10 elImage" style="width: 150px; height: 150px" fit="contain" :src="item">
            </el-image>
            <i @click="deleteImg(index)" class="delete-btn el-icon-close"></i>
        </div>

        <!-- <el-upload style="border: 1px solid red;" action="" :limit="1" :show-file-list="false" :disabled="disabled"
            :before-upload="beforeUpload" :http-request="httpRequest" :on-success="onSuccess" ref="upload"
            accept=".jpeg,.jpg,.png">
            <div class="f-w imgBox">
                <el-image class="mb-30 mr-20 borderRa-10 elImage" v-for="(item, index) of imageUrlList" :key="index"
                    style="width: 150px; height: 150px" fit="contain" :src="item">
                    <i @click="deleteImg" class="delete-btn el-icon-close"></i>
                </el-image>
                <div v-if="imageUrlList.length < 5" class="no-img f-cl-c">
                    <img src="../../../assets/upload.png" alt="">
                    <span class="text">点击上传图片</span>
                </div>
            </div>
        </el-upload> -->



        <el-upload v-if="imageUrlList.length < 5" action="" :limit="1" :show-file-list="false" :disabled="disabled" :before-upload="beforeUpload"
            :http-request="httpRequest" :on-success="onSuccess" ref="upload" accept=".jpeg,.jpg,.png">
            <div class="no-img f-cl-c">
                <img src="../../../assets/upload.png" alt="">
                <span class="text">点击上传图片</span>
            </div>
        </el-upload>
        <!-- <i v-if="!disabled && imageUrl && imageUrl != 'D'" @click="deleteImg" class="delete-btn el-icon-close"></i> -->
    </div>
</template>

<script>
import { beforeUploadMixin } from '@/mixins/BeforeUploadImageMixin';
export default {
    props: {
        imageUrl: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        imageUrlList: {
            type: Array,
            defalut: []
        },
    },
    mixins: [beforeUploadMixin],
    methods: {
        httpRequest(file) {
            this.uploadImg(file)
        },
        uploadImg(params) {
            let that = this;
            that.$emit('FormData', params)
            this.onSuccess();
        },
        onSuccess() {
            this.$refs["upload"].clearFiles();
        },
        deleteImg(idx) {
            console.log('deleteImg',idx)
            this.$emit('deleteUploadImage',idx)
        },

    },
}

</script>
<style lang='less' scoped>
.has-img {

    // position: relative;
    // .elImage {
    //     position: relative;
    // }

    .delete-btn {
        position: absolute;
        left: 140px;
        top: -5px;
        border: 1px solid #333;
        border-radius: 50%;
        // padding: 2px;
        color: #333;
        background: #fff;
    }

    .delete-btn:hover {
        background: red;
        border: 1px solid red;
        color: #fff;
    }


}

.no-img {
    width: 196px;
    height: 146px;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;

    .text {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
    }
}
</style>
